<template>
  <base-layout
    :title="title"
    :backLink="backLink"
    :reloadable="reloadable"
    :footer="true"
  >
    <template v-slot:search-bar>
      <ion-toolbar size="small" v-show="loadedStudent">
        <ion-title slot="start" class="ion-text-capitalize" size="small">
          <ion-text>
            {{ loadedStudent ? loadedStudent.user_detail.name : "" }}
          </ion-text>
        </ion-title>

        <ion-title class="ion-text-capitalize" size="small">
          <ion-text>
            {{
              loadedStudent
                ? loadedStudent.student.section_standard.section.name
                : ""
            }}
            -
            {{ loadedStudent ? loadedStudent.student.roll_no : "" }}
          </ion-text>
        </ion-title>

        <ion-title slot="end" class="ion-text-capitalize" size="small">
          <ion-text color="dark">{{ exam_name }}</ion-text>
        </ion-title>
      </ion-toolbar>
    </template>

    <ion-card v-if="loadedQuestion && loadedAnswer">
      <ion-card-header class="ion-no-padding">
        <ion-item lines="none">
          <ion-card-subtitle slot="start" color="dark">
            Q {{ loadedQuestionIndex + 1 }}
          </ion-card-subtitle>
          <ion-card-subtitle
            slot="end"
            class="ion-text-end"
            color="dark"
            v-if="examSubject"
          >
            marks:
            <span style="color: green">{{ loadedQuestion.marks }}</span> (<span
              style="color: red"
            >
              -{{
                (examSubject.negative_percentage / 100) * loadedQuestion.marks
              }} </span
            >)
          </ion-card-subtitle>
        </ion-item>
      </ion-card-header>

      <ion-card-content
        style="color: black"
        v-html="loadedQuestion.description"
      ></ion-card-content>

      <section>
        <ion-card-content
          v-if="loadedQuestion.exam_question_type.name == 'Descriptive'"
          style="color: black"
        >
          <ion-card-subtitle slot="start" color="dark">
            Answer:
          </ion-card-subtitle>
          <section v-html="loadedAnswer.description"></section>
        </ion-card-content>
        <ion-card-content
          v-if="loadedQuestion.exam_question_type.name == 'Descriptive'"
          class="marks-secured-field"
        >
          <ion-item lines="none" class="ion-no-padding">
            <ion-label>Marks Secured:</ion-label>
            <ion-input
              type="number"
              step="0.01"
              v-model="entered_marks"
              placeholder="0.00"
            ></ion-input>
          </ion-item>
        </ion-card-content>

        <ion-card-content
          v-if="loadedQuestion.exam_question_type.name == 'Objective'"
          class="ion-no-padding"
        >
          <ion-list>
            <ion-list lines="none">
              <ion-list-header>
                <ion-label>Answers:</ion-label>
              </ion-list-header>
              <ion-item
                v-for="item in loadedQuestion.exam_question_options"
                :key="item.id"
              >
                <ion-icon
                  slot="start"
                  :color="
                    loadedAnswer.exam_question_option_id == item.id &&
                    item.is_correct
                      ? 'success'
                      : ''
                  "
                  :ios="
                    loadedAnswer.exam_question_option_id == item.id
                      ? radioButtonOnOutline
                      : radioButtonOffOutline
                  "
                  :md="
                    loadedAnswer.exam_question_option_id == item.id
                      ? radioButtonOnSharp
                      : radioButtonOffSharp
                  "
                ></ion-icon>
                <ion-label>
                  <span
                    class="ion-text-wrap"
                    :class="{
                      'correct-answer': item.is_correct == true,
                      'wrong-answer':
                        item.is_correct == false &&
                        loadedAnswer.exam_question_option_id == item.id,
                    }"
                  >
                    {{ item.description }}
                  </span>
                </ion-label>
              </ion-item>
              <ion-item class="ion-margin-top">
                <ion-label
                  >Marks Secured: {{ loadedAnswer.marks_secured }}</ion-label
                >
              </ion-item>
            </ion-list>
          </ion-list>
        </ion-card-content>
      </section>
    </ion-card>

    <ion-card v-else>
      <ion-card-header>
        <ion-card-title>{{ exam_name }}</ion-card-title>
        <ion-card-subtitle
          >Please select student for Evaluation</ion-card-subtitle
        >
      </ion-card-header>
      <ion-card-content>
        <ion-list lines="none" v-if="examSubject">
          <ion-item class="ion-no-padding">
            <ion-label slot="start"
              >Full Marks: {{ examSubject.full_mark }}
            </ion-label>
            <ion-label class="ion-text-end"
              >Pass Mark: {{ examSubject.pass_mark }}
            </ion-label>
          </ion-item>
          <ion-item class="ion-no-padding">
            <ion-label>
              {{ moment(examSubject.exam_schedule.start).format("ll") }}:
              {{ moment(examSubject.exam_schedule.start).format("LT") }} to
              {{ moment(examSubject.exam_schedule.end).format("LT") }}
            </ion-label>
          </ion-item>
          <ion-item class="ion-no-padding" v-if="allStudents">
            <ion-label>Total Students: {{ allStudents.length }}</ion-label>
          </ion-item>
        </ion-list>
        <ion-title
          align="center"
          style="border: 3px solid #a0ff27; border-radius: 6px"
        >
          <ion-select
            placeholder="Select student"
            :interface-options="{ cssClass: 'full-screen' }"
            v-model="selected_user_id"
          >
            <ion-select-option
              v-for="item in allStudents"
              :key="item.id"
              :value="item.id"
            >
              {{ item.user_detail.name }}-
              {{ item.student.section_standard.standard.name }}-{{
                item.student.section_standard.section.name
              }}
              (Roll {{ item.student.roll_no }})
            </ion-select-option>
          </ion-select>
        </ion-title>
      </ion-card-content>
    </ion-card>

    <template v-slot:footer>
      <ion-toolbar v-show="loadedStudent">
        <ion-button
          slot="start"
          fill="solid"
          :disabled="!prevQuestion"
          :color="!prevQuestion ? 'medium' : ''"
          @click="navigateQuestion('prev')"
        >
          Save & Prev
        </ion-button>
        <ion-title align="center">
          <ion-button
            size="small"
            fill="clear"
            @click="setStatsModalState(true)"
          >
            <ion-icon
              slot="icon-only"
              :ios="appsOutline"
              :md="appsOutline"
            ></ion-icon>
          </ion-button>
        </ion-title>
        <ion-button
          slot="end"
          color="success"
          fill="solid"
          @click="navigateQuestion('next')"
        >
          {{ nextQuestion ? "Save & Next" : "Save & View" }}
        </ion-button>
      </ion-toolbar>
    </template>

    <ion-modal
      :is-open="statsModalState"
      @didDismiss="setStatsModalState(false)"
    >
      <base-modal
        :title="title"
        @closeModal="setStatsModalState(false)"
        :footer="true"
      >
        <template v-slot:search-bar>
          <ion-toolbar size="small">
            <ion-title slot="start" class="ion-text-capitalize" size="small">
              <ion-text color="dark"
                >Total Q: {{ allQuestions.length }}</ion-text
              >
            </ion-title>

            <ion-title slot="end" class="ion-text-capitalize" size="small">
              <ion-text> {{ exam_name }} </ion-text>
            </ion-title>
          </ion-toolbar>
        </template>

        <online-exam-stats
          @loadQuestion="loadQuestionFromStatsPage"
          :allStudents="allStudents"
          :allQuestions="allQuestions"
          :allAnswers="allAnswers"
          :exam_name="exam_name"
          :examSubject="examSubject"
          :examAnswerStates="examAnswerStates"
          :selected_user_id="selected_user_id"
          :loadedStudent="loadedStudent"
        ></online-exam-stats>

        <template v-slot:footer>
          <ion-toolbar>
            <ion-button
              slot="start"
              fill="solid"
              :disabled="!prevStudent"
              :color="!prevStudent ? 'medium' : ''"
              @click="selectUser(prevStudent)"
            >
              Prev
            </ion-button>
            <ion-title align="center">
              <ion-select
                placeholder="Select Student"
                :interface-options="{ cssClass: 'full-screen' }"
                v-model="selected_user_id"
                @ionChange="setStatsModalState(false)"
              >
                <ion-select-option
                  v-for="item in allStudents"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.user_detail.name }}-
                  {{ item.student.section_standard.standard.name }}-{{
                    item.student.section_standard.section.name
                  }}
                  (Roll {{ item.student.roll_no }})
                </ion-select-option>
              </ion-select>
            </ion-title>
            <ion-button
              slot="end"
              :color="!nextStudent ? 'medium' : 'success'"
              fill="solid"
              :disabled="!nextStudent"
              @click="selectUser(nextStudent)"
            >
              Next
            </ion-button>
          </ion-toolbar>
        </template>
      </base-modal>
    </ion-modal>
  </base-layout>
</template>

<script>
import moment from "moment";
import BaseLayout from "../../Base/BaseLayout";

import {
  IonToolbar,
  IonTitle,
  // IonButtons,
  IonModal,
  IonText,
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonList,
  IonListHeader,
  IonLabel,
  loadingController,
  alertController,
  IonInput,
  IonCardTitle,
} from "@ionic/vue";

import {
  appsOutline,
  radioButtonOnOutline,
  radioButtonOffOutline,
  radioButtonOnSharp,
  radioButtonOffSharp,
} from "ionicons/icons";
// import moment from "moment";

// import Editor from "@tinymce/tinymce-vue";
import base_url from "../../../apis/base_url";

import BaseModal from "../../Base/BaseModal";
import OnlineExamStats from "../../Components/Exam/OnlineExam/OnlineExamStats.vue";

export default {
  props: ["exam_subject_id", "exam_name", "title"],

  components: {
    BaseLayout,
    BaseModal,
    OnlineExamStats,
    IonModal,
    // Editor,

    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardSubtitle,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonList,
    IonListHeader,
    IonLabel,
    IonToolbar,
    IonTitle,
    IonText,
    IonButton,
    IonIcon,
    IonInput,
    IonCardTitle,
  },

  data() {
    return {
      moment,
      backLink: "exam.list",
      reloadable: false,
      statsModalState: false,
      appsOutline,
      base_url,
      radioButtonOnOutline,
      radioButtonOffOutline,
      radioButtonOnSharp,
      radioButtonOffSharp,

      selected_user_id: null,
      loaded_question_id: null,
      entered_marks: null,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    marks_for_update() {
      return this.entered_marks ? this.entered_marks : 0;
    },
    examSubject() {
      return this.$store.getters["examSubject/specificResource"];
    },
    allQuestions() {
      return this.$store.getters["examQuestion/allResources"];
    },
    allStudents() {
      return this.$store.getters["studentUsersForExamSubject/allResources"];
    },
    examAnswerStates() {
      return this.$store.getters["attribute/examAnswerState"];
    },
    loadedStudent() {
      if (!this.allStudents || !this.selected_user_id) {
        return null;
      }
      return this.allStudents.find((item) => item.id == this.selected_user_id);
    },
    nextStudent() {
      if (!this.allStudents || !this.selected_user_id) {
        return null;
      }
      let index = this.allStudents.findIndex(
        (item) => item.id == this.selected_user_id
      );
      if (index == this.allStudents.length - 1) {
        return null;
      }
      return this.allStudents[index + 1];
    },
    prevStudent() {
      if (!this.allStudents || !this.selected_user_id) {
        return null;
      }
      let index = this.allStudents.findIndex(
        (item) => item.id == this.selected_user_id
      );
      if (index == 0) {
        return null;
      }
      return this.allStudents[index - 1];
    },
    allAnswers() {
      return this.$store.getters["examAnswer/allResources"];
    },
    loadedQuestion() {
      return this.loaded_question_id
        ? this.allQuestions.find((item) => item.id == this.loaded_question_id)
        : null;
    },
    loadedQuestionIndex() {
      const allQuestions = this.allQuestions;
      if (!allQuestions || !this.loaded_question_id) {
        return null;
      }
      return allQuestions.findIndex(
        (item) => item.id == this.loaded_question_id
      );
    },
    loadedAnswer() {
      if (this.loadedQuestion && this.allAnswers) {
        let loaded_answer = this.allAnswers.find(
          (answer) => answer.exam_question_id == this.loadedQuestion.id
        );
        return loaded_answer ? loaded_answer : false;
      } else {
        return false;
      }
    },
    nextQuestion() {
      if (this.loadedAnswer) {
        let next_question = this.allAnswers[this.loadedQuestionIndex + 1];
        return next_question ? next_question : false;
      } else {
        return false;
      }
    },
    prevQuestion() {
      if (this.loadedAnswer) {
        let prev_question = this.allAnswers[this.loadedQuestionIndex - 1];
        return prev_question ? prev_question : false;
      } else {
        return false;
      }
    },
  },

  watch: {
    loadedStudent(newLoadedStudent) {
      if (newLoadedStudent != null) {
        this.getAllAnswers();
      }
    },

    loadedAnswer(newLoadedAnswer) {
      if (newLoadedAnswer) {
        this.entered_marks =
          newLoadedAnswer.marks_secured > 0
            ? newLoadedAnswer.marks_secured
            : null;
      }
    },
  },

  methods: {
    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    async getAllAnswers() {
      const data = {
        user_id: this.loadedStudent.id,
        exam_subject_id: this.exam_subject_id,
      };
      await this.$store.dispatch("examAnswer/allResources", data);
      await this.loadExamQuestion(this.allAnswers[0].exam_question_id);
    },

    async loadExamQuestion(exam_question_id) {
      this.loaded_question_id = exam_question_id;
    },

    async getAllQuestions() {
      await this.$store.dispatch(
        "examQuestion/allResources",
        this.exam_subject_id
      );
    },

    async getAllStudents() {
      await this.$store.dispatch(
        "studentUsersForExamSubject/allResources",
        this.exam_subject_id
      );
    },

    async getExamSubject() {
      await this.$store.dispatch(
        "examSubject/specificResource",
        this.exam_subject_id
      );
    },

    async getExamAnswerStates() {
      await this.$store.dispatch("attribute/examAnswerState");
    },

    async loadQuestionFromStatsPage(id) {
      await this.loadExamQuestion(id);
      this.setStatsModalState(false);
    },

    setStatsModalState(state) {
      this.statsModalState = state;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async updateMarks(direction) {
      const data = {
        exam_answer_id: this.loadedAnswer.id,
        marks_secured: this.marks_for_update,
        exam_subject_id: this.exam_subject_id,
        user_id: this.loadedStudent.id,
      };
      const spinner = await this.loader("Saving...");
      spinner.present();
      try {
        await this.$store.dispatch("examAnswer/evaluate", data);
        this.entered_marks = null;
        await this.navigate(direction);
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      } finally {
        spinner.dismiss();
      }
    },
    async navigateQuestion(direction = "next") {
      if (this.loadedQuestion.exam_question_type.name == "Descriptive") {
        await this.updateMarks(direction);
      } else if (this.loadedQuestion.exam_question_type.name == "Objective") {
        this.navigate(direction);
      }
    },

    async navigate(direction) {
      if (direction === "next") {
        this.gotoNextQuestion();
      }
      if (direction === "prev" && this.prevQuestion) {
        this.gotoPrevQuestion();
      }
    },

    async gotoNextQuestion() {
      let nextQuestion = this.nextQuestion;
      if (nextQuestion) {
        await this.loadExamQuestion(this.nextQuestion.exam_question_id);
      } else {
        this.setStatsModalState(true);
      }
    },
    async gotoPrevQuestion() {
      let prevQuestion = this.prevQuestion;
      if (prevQuestion) {
        await this.loadExamQuestion(this.prevQuestion.exam_question_id);
      }
    },
    async selectUser(user) {
      if (user) {
        this.selected_user_id = user.id;
      }
    },
  },

  created() {
    this.getAllQuestions();
    this.getExamSubject();
    this.getExamAnswerStates();
    this.getAllStudents();
  },
};
</script>

<style scoped>
ion-toolbar {
  --min-height: 40px !important;
}

.correct-answer {
  color: green;
}

.wrong-answer {
  color: darkred;
}
.marks-secured-field {
  border-top: rgb(187, 187, 187) 2px solid;
  margin-top: 1.5rem;
}
</style>
