<template>
  <ion-list lines="none" style="border-bottom: darkgrey solid 3px">
    <ion-item class="ion-no-padding">
      <ion-label class="ion-text-wrap"> Name: {{ loadedStudent.user_detail.name }} </ion-label>
      <ion-label slot="end" class="ion-text-end">
        Class: {{ loadedStudent.student.section_standard.standard.name }}
        {{ loadedStudent.student.section_standard.section.name }}
        Roll:{{ loadedStudent.student.roll_no }}</ion-label
      >
    </ion-item>
    <ion-item class="ion-no-padding">
      <ion-label
        >Marks secured:
        <span
          :class="total_marks_secured < examSubject.pass_mark ? 'fail' : 'pass'"
          >{{ total_marks_secured }}</span
        >
      </ion-label>
      <ion-label slot="end" class="ion-text-end"
        >Marks (f/p): {{ examSubject.full_mark }} / {{ examSubject.pass_mark }}
      </ion-label>
    </ion-item>
  </ion-list>
  <ion-list v-if="loadedStudent">
    <ion-item class="ion-no-padding">
      <ion-label> Objective </ion-label>
      <ion-badge slot="end" color="dark" class="badge">
        {{ countObjective() }}
      </ion-badge>
    </ion-item>
    <ion-item class="ion-no-padding">
      <ion-label> Evaluated </ion-label>
      <ion-badge slot="end" color="success" class="badge">
        {{ count("Evaluated") }}
      </ion-badge>
    </ion-item>
    <ion-item class="ion-no-padding">
      <ion-label> Not Evaluated </ion-label>
      <ion-badge slot="end" color="warning" class="badge">
        {{ count("NA") }}
      </ion-badge>
    </ion-item>
  </ion-list>

  <div
    class="container ion-margin-top ion-padding-top"
    v-if="allQuestions && allAnswers"
  >
    <ion-badge
      class="badge-sm"
      v-for="(item, index) in allQuestions"
      :key="item.id"
      :color="getBadgeColor(item)"
      @click="loadQuestion(item.id)"
    >
      {{ index + 1 }}
    </ion-badge>
  </div>
</template>

<script>
import { IonBadge, IonList, IonItem, IonLabel } from "@ionic/vue";

export default {
  props: [
    "allStudents",
    "allQuestions",
    "allAnswers",
    "exam_name",
    "examSubject",
    "examAnswerStates",
    "selected_user_id",
    "loadedStudent",
  ],
  emits: ["loadQuestion"],
  components: {
    IonBadge,
    IonList,
    IonItem,
    IonLabel,
  },

  data() {
    return {
      loaded_user_id: this.selected_user_id,
    };
  },

  computed: {
    total_marks_secured() {
      let total = 0;
      this.allAnswers.forEach((answer) => {
        total += answer.marks_secured ? parseFloat(answer.marks_secured) : 0;
      });
      return total;
    },
  },

  methods: {
    getBadgeColor(item) {
      if (item.exam_question_type.name == "Objective") {
        return "dark";
      } else {
        let answer = this.allAnswers.find(
          (answer) => answer.exam_question_id == item.id
        );
        if (answer.evaluated_by !== null) {
          return "success";
        } else {
          return "warning";
        }
      }
    },

    count(status) {
      const evaluated = this.allAnswers.filter(
        (item) => item.evaluated_by != null
      ).length;
      const objectives = this.countObjective();

      return status == "Evaluated"
        ? evaluated
        : this.allAnswers.length - evaluated - objectives;
    },

    countObjective() {
      return this.allQuestions.filter(
        (item) => item.exam_question_type.name == "Objective"
      ).length;
    },

    loadQuestion(id) {
      this.$emit("loadQuestion", id);
    },
  },

  mounted() {},
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.badge {
  border-radius: 35%;
  min-width: 1.8rem;
  min-height: 1.8rem;
  text-align: center;
  padding: 0.5rem;
}
.badge-sm {
  border-radius: 35%;
  min-width: 1.6rem;
  min-height: 1.6rem;
  text-align: center;
  padding: 0.4rem;
  margin: 0.4rem;
}
.fail {
  color: red;
}
.pass {
  color: darkgreen;
}
</style>